import React from "react";

export default function ProductDetails({ product }) {
  if (!product) {
    return (
      <div className="mb-4 text-3xl text-gray-900 md:text-5xl lg:text-6xl">
        No product details available.
      </div>
    );
  }

  return (
    <div className="fade1 product-details text-center py-6 bg-gradient-to-r from-red-100 via-purple-100 to-red-100 h-full rounded-xl mb-auto mt-[5px] max-w-[1000px] mx-auto px-4 sm:px-6 lg:px-8 sm:py-8 lg:py-6">
      <h1 class="mb-4 text-start font-semibold myfont1 text-3xl text-gray-900 md:text-1xl lg:text-3xl">
        <span class="text-transparent mr-1  bg-clip-text bg-gradient-to-r  to-blue-500 from-red-500">
          {product.name}
        </span>
        {product.subname}
      </h1>
      <div className="flex justify-center items-center">
        <img className=" fade pt-3" src={product.pic1} alt={product.name} />
      </div>

      <p class="py-4 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description1}
      </p>

      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-64 h-1 my-8 bg-gray-500 border-0 rounded" />
        <div class="absolute px-4 -translate-x-1/2 bg-gray-500 rounded-sm left-1/2 ">
          <svg
            class="w-4 h-5 text-gray-100 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img
          className="h-full fade py-3"
          src={product.pic2}
          alt={product.name}
        />
      </div>
      <p class="py-4 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description2}
      </p>

      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-64 h-1 my-8 bg-gray-500 border-0 rounded" />
        <div class="absolute px-4 -translate-x-1/2 bg-gray-500 rounded-sm left-1/2 ">
          <svg
            class="w-4 h-5 text-gray-100 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img
          className="h-full fade rounded-md py-3"
          src={product.pic3}
          alt={product.name}
        />
      </div>

      <p class="py-3 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description3}
      </p>
      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-64 h-1 my-8 bg-gray-500 border-0 rounded" />
        <div class="absolute px-4 -translate-x-1/2 bg-gray-500 rounded-sm left-1/2 ">
          <svg
            class="w-4 h-5 text-gray-100 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </div>
      </div>

      <p class="py-3 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description4}
      </p>
    </div>
  );
}
