const stats = [
  {
    name: "remandeltech@gmail.com",
    name2: "info@remandeltech.com ",
    value: "Email",
    href: "",
  },
  {
    name: " 8287441798",
    name2: "8810396729   ",
    value: "Phone",
    href: "",
  },
  {
    name:"Plot no 15 a sgm nagar sector-48 near cia chowki Faridabad Haryana (121001)",
    name2: " ",
    value: "Address",
    href: "",
  },
];

export default function Example() {
  return (
    <div className=" fade1 h-[90vh] bg-gradient-to-r from-red-100 via-sky-100 to-red-100 relative isolate myfont  overflow-hidden bg-gray-100  mt-[5px] mb-auto mx-auto sm:px-6 lg:px-8 lg:py-8 lg:pt-28 sm:py-10 pt-10">
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          // className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        // className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          // className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
            Contact Us
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-900">
            We’d love to hear from you! Whether you have questions about our
            products, need technical support, or want to discuss a potential
            partnership, feel free to reach out to us.
          </p>
        </div>
        <div className="mx-auto mt-4 max-w-2xl lg:mx-0 lg:max-w-none">
          <dl className="mt-5 grid grid-cols-1 gap-8 sm:mt-4 sm:grid-cols-1 lg:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.name} className="">
                <dd className="text-2xl text-transparent mr-1  bg-clip-text bg-gradient-to-r  to-blue-800 from-red-800 fade font-bold leading-9 tracking-tight ">
                  {stat.value}
                </dd>
                <dt className=" text-base leading-7 text-gray-700">
                  {stat.name}
                </dt>
                <dt className=" text-base leading-7 text-gray-700">
                  {stat.name2}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
