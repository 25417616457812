import React from "react";

import ProductCard from "./ProductCard";

import Product from "./Product";
import piclogo from "../assets/piclogo.png";
import piclogo1 from "../assets/piclogo1.jpeg";
// import piclogo2 from "../assets/piclogo2.jpeg";

import test1 from "../assets/test1.png";
import test2 from "../assets/test2.jpg";
import test3 from "../assets/test3.jpg";
import test4 from "../assets/test4.png";
import test5 from "../assets/test5.jpeg";
import test6 from "../assets/test6.png";
import safe from "../assets/safe.jpg";


const products = [
  {
    id: 1,
    pic1: test1,
    pic2: test2,
    pic3: test3,
    piclogo: piclogo,
    name: " FRS System",
    // subname: "System",
    description1:
      "Our facial recognition system (FRS) is an advanced, automated, and adaptive solution designed to detect and recognize faces from a pre-encoded face database. It seamlessly handles various types of input data, including real-time video feeds, archived videos, images, and digital photographs, ensuring robust performance across different scenarios. ",
    description2:
      "Leveraging deep learning algorithms, our system offers improved accuracy and confidence levels, delivering over 95% accuracy for single face detection per frame and maintaining over 92% accuracy for up to five faces per frame in non-constrained environments. The face detection accuracy exceeds 98%, demonstrating its reliability in diverse conditions. The system automatically stores cropped face images and recognition data in a SQL database, complete with timestamps and location IDs for precise tracking. It is capable of real-time operation with live video feeds and stored videos, and supports a wide detection angle of 30°, ±15° from normal. Both raw and processed data are stored for comprehensive analytics, ensuring accuracy even with moving persons. Our FRS includes a user-friendly web app front-end for quick face registration, requiring less than 35 seconds per face. It is compatible with various IP camera models and specifications, and delivers similar accuracy with webcams.",
    description3:
      "The solution is IP-based and integrates seamlessly with Central Control Room setups, offering real-time alerts and alarms for exceptions like 'blacklisted' persons. A mobile app for iOS and Android devices allows for both face registration and real-time detection. The system also provides management with MIS reports and optional historical and predictive analytics.",
    description4:
      "The appliance supports up to eight channels and requires robust hardware specifications, including an Intel i7/Xeon CPU, 16GB DDR4 RAM, 120GB SSD, 1TB SATA Drive, and an NVIDIA P4000 GPU with 8/11GB DDR5 memory, running on Linux (Ubuntu 16/18). Available in both appliance and server modes, the system supports databases like MySQL, MariaDB, or Oracle. Overall, our FRS offers a comprehensive, high-accuracy, and scalable solution for facial recognition needs.",
  },
  {
    id: 2,
    pic1: test4,
    pic2: test6,
    pic3: test5,
    piclogo: piclogo1,
    name: "ANPR System",
    description1:
      "The ANPR system is a state-of-the-art automated solution designed for seamless license plate recognition (LPR) and efficient database management. Leveraging advanced deep learning (DL) and machine learning (ML) algorithms, it ensures high accuracy, delivering over 94% accuracy for four-wheelers and over 80% for two/three-wheelers in non-constrained environments. ",
    description2:
      "The system supports various input forms, including real-time video feeds, archived videos, and images, and stores both cropped license plate images and OCR data in the database. ",
    description3:
      "It is adaptable and trainable to recognize different types and fonts of license plates, operating independently of specific fonts. Compatible with any 4 MP 60 fps IP camera with a 5-50 mm lens, the  ANPR system functions effectively in real-time, even at vehicle speeds of up to 90 kmph, and detects both commercial and private license plates.",
    description4:
      " Additionally, it provides a wide detection angle of 60°, ±30° from normal, and ensures comprehensive data storage with timestamps and camera location IDs. The solution also offers optional features like real-time dashboards, alerts for violations, and comprehensive analytics reports. Supported by powerful hardware, the system integrates effortlessly with central control rooms, making it an ideal choice for modern ANPR applications.",
  },
];

const productCard = [
  {
    id: 3,
    pic1: safe,
    pic2: test2,
    pic3: test3,
    piclogo: safe,

    name: "RemanDel Tech camera based AI system",
    description1:
      "Camera-based AI system involves combining CCTV technology with artificial intelligence to enhance security, surveillance, and data analysis capabilities. In these there are different product",
    description2:
      "Inarecon secure - Secures Boundaries from unauthorized. Entries with alaram and alerts monitors and alerts movement of people and material along perimeters automated monitoring of vehicle movement and number plate capture at gate automated visitor management and tracking at gate/reception.",
    description3:
      "Imarecon resources - Automated management  and tracking suppliers and sub-contractors face recognition based attendance systeam tracking  employee movement automated capture and report machine and jig efficency on daily basis automated monitoring of employee productivity accurate objects and people counting details analysis and report module",
    description4:
      "Imarecon safe - Automated capture and alarm /alert on missing ‘safety helmet’,’safety jacket’ detecting accident and reporting including fall auto detection of loitering or entry in ‘no entery’ / unauthorised zone with alarm/alert and fire , smoke detection",
  },
];

export default function Products({
  showProductDetails,
  showProductDetailsCard,
}) {
  return (
    <>
      <section className=" fade1 bg-gradient-to-r from-red-100 via-sky-100 to-red-100 h-full rounded-xl mb-auto mt-[5px] max-w-[1000px] mx-auto sm:px-6 lg:px-8 sm:py-8 lg:py-6">
        <div className="mx-auto text-bold text-3xl py-5 px-5 myfont max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
            Our Products
          </h2>
        </div>

        <div className=" space-y-[5px]">
          {products.map((product) => (
            <div key={product.id}>
              <hr class=" pb-2 border-gray-400"></hr>
              <Product
                description={product.description1}
                pic={product.piclogo}
                name={product.name}
                showProductDetails={() => showProductDetails(product)}
              />
            </div>
          ))}

          {productCard.map((productCard) => (
            <div key={productCard.id}>
              <hr class=" pb-2 border-gray-400"></hr>
              <ProductCard
                description={productCard.description1}
                pic={productCard.piclogo}
                name={productCard.name}
                showProductDetailsCard={() =>
                  showProductDetailsCard(productCard)
                }
              />
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
