import React from "react";

import secure from "../assets/secure.jpg";
import safe from "../assets/safe.jpg";
import cctv from "../assets/cctv.jpg";
import attendance from "../assets/attendance.jpg";
import Card from "./Card";

const carddetails = [
  {
    id: 1,
    name: "RD secure",
    pic: secure,
    description:
      " Secures Boundaries from unauthorized Entries with alaram and alerts monitors and alerts movement of people and material along perimeters automated monitoring of vehicle movement and number plate capture at gate automated visitor management and tracking at gate/reception",
  },
  {
    name: "RD resources",
    id: 2,
    pic: cctv,
    description:
      "   Automated management  and tracking suppliers and sub-contractors face recognition based attendance systeam tracking  employee movement automated capture and report machine and jig efficency on daily basis automated monitoring of employee productivity accurate objects and people counting details analysis and report module",
  },
  {
    name: "RD safe",
    id: 3,
    pic: safe,
    description:
      "Automated capture and alarm /alert on missing ‘safety helmet’,’safety jacket’ detecting accident and reporting including fall auto detection of loitering or entry in ‘no entery’ / unauthorised zone with alarm/alert and fire , smoke detection",
  },
  {
    name: "RD quality ",
    pic: attendance,
    id: 4,
    description:
      " Automated quality check using fixed cctv camera to accurately detect and report exception in defined color , defined shape and size and Dimensions etc Accurate estimation of area and volume of finished products either in shop-floor or store",
  },
];

export default function ProductDetailsCard({ product, showCCTV }) {
  if (!product) {
    return (
      <div className="mb-4 text-3xl text-gray-900 md:text-5xl lg:text-6xl">
        No product details available.
      </div>
    );
  }

  return (
    <div className="fade1 product-details text-center py-6 bg-gradient-to-r from-red-100 via-purple-100 to-red-100 h-full rounded-xl mb-auto mt-[5px] max-w-[1000px] mx-auto px-4 sm:px-6 lg:px-8 sm:py-8 lg:py-6">
      <h1 class="mb-4 text-start font-semibold myfont1 text-3xl text-gray-900 md:text-1xl lg:text-3xl">
        <span class="text-transparent mr-1  bg-clip-text bg-gradient-to-r  to-blue-500 from-red-500">
          {product.name}
        </span>
        {product.subname}
      </h1>

      <p class="py-4 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description1}
      </p>

      {carddetails.map((detail) => (
        <div
          className="items-center justify-center text-center flex"
          key={detail.id}
        >
          {/* <hr class=" pb-2 border-gray-400"></hr> */}
          <Card
            showCCTV={showCCTV}
            description={detail.description}
            pic={detail.pic}
            name={detail.name}
            id={detail.id}
          />
        </div>
      ))}

      {/* 
      <div className="flex justify-center items-center">
        <img
          className="h-full fade py-3"
          src={product.pic2}
          alt={product.name}
        />
      </div>
      <p class="py-4 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description2}
      </p>

      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-64 h-1 my-8 bg-gray-500 border-0 rounded" />
        <div class="absolute px-4 -translate-x-1/2 bg-gray-500 rounded-sm left-1/2 ">
          <svg
            class="w-4 h-5 text-gray-100 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img
          className="h-full fade rounded-md py-3"
          src={product.pic3}
          alt={product.name}
        />
      </div>

      <p class="py-3 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description3}
      </p>
      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-64 h-1 my-8 bg-gray-500 border-0 rounded" />
        <div class="absolute px-4 -translate-x-1/2 bg-gray-500 rounded-sm left-1/2 ">
          <svg
            class="w-4 h-5 text-gray-100 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </div>
      </div>

      <p class="py-3 myfont text-lg font-normal text-gray-800 lg:text-xl">
        {product.description4}
      </p> */}
    </div>
  );
}
