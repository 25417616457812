const links = [
  { name: "Open roles", href: "#" },
  { name: "Internship program", href: "#" },
  { name: "Our values", href: "#" },
  { name: "Meet our leadership", href: "#" },
];
const stats = [
  {
    name: "We are at the forefront of AI technology, continuously innovating to deliver the best solutions to our clients.",
    value: "Innovation",
  },
  {
    name: " Our products are designed and tested to ensure the highest standards of quality and reliability.",
    value: "Quality",
  },
  {
    name: " Our team comprises industry experts with extensive knowledge and experience in AI and imaging technologies.",
    value: "Expertise",
  },
  {
    name: "We prioritize our customers' needs, providing tailored solutions and exceptional support to help them succeed.",
    value: "Customer Focus",
  },
];

export default function Example() {
  return (
    <div
      className=" fade1 bg-gradient-to-r from-red-100 via-sky-100 to-red-100 relative rounded-xl isolate myfont overflow-hidden bg-gray-100  mt-[5px] mb-auto mx-auto sm:px-6 lg:px-8 h-full lg:py-6 sm:py-20 pt-9"
      id="about"
    >
      {/* <img
          alt=""
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        /> */}
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          // className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        // className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          // className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
            About Us
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-900">
            Welcome to<b> Reman Del Tech</b> , where innovation meets precision.
            We are a leading provider of AI-powered scan camera solutions,
            dedicated to transforming the way businesses and individuals capture
            and analyze visual data.
          </p>
        </div>
        <div className="mx-auto mt-4 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="mt-3 text-lg leading-8 text-gray-900">
            Our product are equipped with advanced features that enable seamless
            scanning, recognition, and analysis of various objects and
            environments. Utilizing the latest in machine learning algorithms,
            our cameras can identify, classify, and interpret visual data with
            unparalleled accuracy and speed.
            {/* {links.map((link) => (
                <a key={link.name} href={link.href}>
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </a>
              ))} */}
          </div>
          <h1 className="mt-10 text-2xl leading-8 font-bold text-black">
            Why Choose Us?
          </h1>

          <dl className="mt-5 grid grid-cols-1 gap-8 sm:mt-4 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="">
                <dd className="text-2xl text-transparent mr-1  bg-clip-text bg-gradient-to-r  to-blue-800 from-red-800 fade font-bold leading-9 tracking-tight ">
                  {stat.value}
                </dd>
                <dt className=" text-base leading-7 text-gray-700">
                  {stat.name}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
