import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logoo from "../assets/logoo.png";

const navigation = [
  { name: "Home", action: "goHome", current: false },
  { name: "Products", action: "showProducts", current: false },
  { name: "About", action: "showAbout", current: false },
  { name: "Contact", action: "showContact", current: false },
  // { name: "CCTV", action: "showCCTV", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ goHome, showProducts, showAbout, showContact }) {
  const handleNavigation = (action) => {
    if (action === "goHome") goHome();
    if (action === "showProducts") showProducts();
    if (action === "showAbout") showAbout();
    if (action === "showContact") showContact();
  };

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-gradient-to-r text-center justify-center from-gray-800 via-gray-600 to-gray-800 myfont z-40 shadow-2xl w-full top-0 fixed backdrop-blur-[15px] backdrop-saturate-[150%]"
      >
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-[72px] items-center justify-between">
            <div className="absolute inset-y-0 right-1 flex items-center sm:hidden">
              <Disclosure.Button className="inline-flex items-center p-2 justify-center bg-transparent text-sm text-gray-200 hover:text-white rounded-lg md:hidden bg-gray-100">
                <span className="absolute -inset-0.5" />
                <Bars3Icon
                  aria-hidden="true"
                  className="block h-12 w-9 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden h-7 w-7 group-data-[open]:block"
                />
              </Disclosure.Button>
            </div>
            <div className="flex flex-1 items-center ml-2 justify-start sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img alt="Ramen" src={logoo} className="h-16 w-auto" />
              </div>
              <div className="hidden items-end sm:ml-16 w-full sm:block">
                <div className="flex space-x-4 justify-end">
                  {navigation.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => handleNavigation(item.action)}
                      aria-current={item.current ? "page" : undefined}
                      className={classNames(
                        item.current ? "text-white" : "buttonNav",
                        " text-end text-base myfont"
                      )}
                    >
                      <p> {item.name}</p>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Disclosure.Panel className="sm:hidden border-none w-full justify-center border-0">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item) => (
              <Disclosure.Button
                key={item.name}
                as="button"
                onClick={() => handleNavigation(item.action)}
                aria-current={item.current ? "page" : undefined}
                className={classNames(
                  item.current
                    ? "text-white w-max-9"
                    : "text-white hover:text-gray-800 w-full",
                  "block bg-gray-600 rounded-md text-center px-3 py-2 text-base myfont"
                )}
              >
                {item.name}
              </Disclosure.Button>
            ))}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </>
  );
}
