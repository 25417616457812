const navigation = { name: "CCTV", action: "showCCTV", current: false };

export default function LandingPage({ showCCTV }) {
  const handleNavigation = (action) => {
    if (action === "showCCTV") showCCTV();
  };

  return (
    <>
      {/* Doosraa */}

      <section class=" py-8 pb-20 fade1 bg-gradient-to-r from-red-100 via-sky-100 to-red-100 transition text-center justify-center rounded-b-xl h-full  items-center ">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center  mt-8 lg:py-16">
          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">
            Boost your productivity.
          </h1>
          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">
            Start using our Products today.
          </h1>
          <p class="mb-8 text-lg font-normal text-gray-600 lg:text-xl sm:px-16 lg:px-48 ">
            Here at <b>Reman Del Tech</b> , We revolutionize security and surveillance by
            leveraging cutting-edge artificial intelligence, providing
            real-time, intelligent monitoring solutions that enhance safety,
            efficiency, and peace of mind for businesses and communities
            worldwide.
          </p>
          <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            <a
              href="#products"
              class=" inline-flex justify-center items-center py-3 px-5 text-base btn font-medium text-center border text-white rounded-lg bg-red-600 border-red-600  hover:text-white hover:bg-red-700  hover:border hover:border-red-700 "
            >
              Our Products
              <svg
                class="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>

            <button
              onClick={() => handleNavigation(navigation.action)}
              className="py-3 buttonL px-5 sm:ms-4 text-sm  font-semibold border rounded-lg text-black hover:bg-gray-00 "
            >
              <a id="products">Install your CCTV</a>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
