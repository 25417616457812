// const stat = [
//   {
//     name: "We are at the forefront of AI technology, continuously innovating to deliver the best solutions to our clients.",
//     value: "Innovation",
//   },
//   {
//     name: " Our products are designed and tested to ensure the highest standards of quality and reliability.",
//     value: "Quality",
//   },
//   {
//     name: " Our team comprises industry experts with extensive knowledge and experience in AI and imaging technologies.",
//     value: "Expertise",
//   },
//   {
//     name: "We prioritize our customers' needs, providing tailored solutions and exceptional support to help them succeed.",
//     value: "Customer Focus",
//   },
// ];
const cctv = [
  {
    name: "-Indoor/Outdoor: Versatile for both environments.",
    value: "Dome CCTV Cameras:",
    name1: "-Features: Vandal-resistant, often with IR night vision.",
  },
  {
    name: "-Outdoor: Typically mounted on walls or ceilings.",
    value: " Bullet CCTV Cameras:",
    name1: "-Features: Long-range visibility, weatherproof.",
  },
  {
    name: " -Specialized Uses: Can accommodate special lenses for different distances.",
    value: " C-Mount CCTV Cameras:",
    name1: " -Features: Detachable lenses, suitable for varying distances.",
  },
  {
    name: " -Dynamic Surveillance: Can pan, tilt, and zoom.",
    value: "PTZ (Pan-Tilt-Zoom) CCTV Cameras:",
    name1: "-Features: Remote control, auto-tracking.",
  },
  {
    name: "-Low Light Conditions: Effective in varying lighting conditions.  ",
    value: "  Day/Night CCTV Cameras:",
    name1: "-Features: Infrared cut filter, sensitive to light.  ",
  },
  {
    name: "-Low Light and No Light: Designed for surveillance in total darkness. ",
    value: " Infrared/Night Vision CCTV Cameras:    ",
    name1: "-Features: Built-in IR LEDs, black-and-white night mode. ",
  },
  {
    name: " -Remote Monitoring: Can be accessed over the internet.   ",
    value: " Network/IP CCTV Cameras: ",
    name1: " -Features: High resolution, POE (Power over Ethernet) ",
  },
  {
    name: "-Flexible Installation: No need for extensive wiring.   ",
    value: "Wireless CCTV Cameras:  ",
    name1: " -Features: Wi-Fi connectivity, often battery-powered  ",
  },
  {
    name: "  -High Clarity: Provides clearer images and videos.  ",
    value: "High Definition (HD) CCTV Cameras:   ",
    name1: "  -Features: 720p, 1080p, and 4K resolutions",
  },
  {
    name: "   ",
    value: "   ",
    name1: "  ",
  },
];

const stats = [
  {
    name: "remandeltech@gmail.com ",
    name2: "info@remandeltech.com ",
    value: "Email",
    href: "",
  },
  {
    name: " 8287441798 ",
    name2: " 8810396729   ",
    value: "Phone",
    href: "",
  },
  {
    name: "Plot no. 15 - a Sgm Nagar  Sector-48 Faridabad, near CIA Chowki",
    value: "Address",
    href: "",
  },
];

export default function Example() {
  return (
    <div
      className=" fade1 bg-gradient-to-r from-red-100 via-sky-100 to-red-100 relative rounded-xl isolate myfont overflow-hidden bg-gray-100  mt-[5px] mb-auto mx-auto sm:px-6 lg:px-8 h-full lg:py-6 sm:py-20 pt-9"
      id="about"
    >
      {/* <img
          alt=""
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        /> */}
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          // className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        // className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          // className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
            Contact Us for CCTV Installation
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-900">
            Protect your property with our professional CCTV installation
            services. Whether you need surveillance for your home, business, or
            any other location, we have the right solution for you. Get in touch
            with us today to discuss your security needs.
          </p>
        </div>
        <div className="mx-auto mt-4 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="mx-auto mt-4 max-w-2xl lg:mx-0 lg:max-w-none">
            <dl className="mt-5 grid grid-cols-1 gap-8 sm:mt-4 sm:grid-cols-1 lg:grid-cols-3">
              {stats.map((stat) => (
                <div key={stat.name} className="">
                  <dd className="text-2xl text-transparent mr-1  bg-clip-text bg-gradient-to-r  to-blue-800 from-red-800 fade font-bold leading-9 tracking-tight ">
                    {stat.value}
                  </dd>
                  <dt className=" text-base leading-7 text-gray-700">
                    {stat.name}
                  </dt>
                  <dt className=" text-base leading-7 text-gray-700">
                    {stat.name2}
                  </dt>
                </div>
              ))}
            </dl>
          </div>
          <h1 className="mt-10 text-2xl leading-8 font-bold text-gray-800">
            There are CCTV installations services, each suited to different
            security needs and environments. Here are the main types:
          </h1>

          <dl className="mt-8  gap-9 sm:mt-7 sm:grid-rows-8 ">
            {cctv.map((cctv) => (
              <div key={cctv.name} className="">
                <dd className="text-2xl text-transparent mr-1  bg-clip-text bg-gradient-to-r  to-blue-800 from-red-800 fade font-bold leading-9 tracking-tight ">
                  {cctv.value}
                </dd>
                <dt className=" text-base leading-7 text-gray-700">
                  {cctv.name}
                </dt>
                <dt className=" text-base leading-7 text-gray-700">
                  {cctv.name1}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
