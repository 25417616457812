import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import LandingPage from "./components/LandingPage";
import Products from "./components/Products";
import About from "./components/About";
import Footer from "./components/Footer";
import Contact from "./components/ContactUs";
import CCTV from "./components/CCTV.jsx";
import ProductDetails from "./components/ProductDetails";
import ProductCard from "./components/ProductCard.jsx";
import ProductDetailsCard from "./components/ProductDetailsCard.jsx";

function App() {
  const [view, setView] = useState("home");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const showProductDetails = (product) => {
    setSelectedProduct(product);
    setView("details");
  };

  
  const showProductDetailsCard = (product) => {
    setSelectedProduct(product);
    setView("carddetails");
  };
  


  const goHome = () => {
    setSelectedProduct(null);
    setView("home");
  };

  const showContact = () => {
    setSelectedProduct(null);
    setView("contact");
  };

  const showCCTV = () => {
    setSelectedProduct(null);
    setView("CCTV");
  };

  const showProducts = () => {
    setView("products");
  };


  const showAbout = () => {
    setView("about");
  };

  

  return (
    <>
      <Navbar
        showContact={showContact}
        goHome={goHome}
        showProducts={showProducts}
        showAbout={showAbout}
      />
      <div className="mt-[72px] mb-auto max-w-[1030px] mx-auto sm:px-6 lg:px-8">
        {view === "home" && (
          <>
            <LandingPage showCCTV={showCCTV} />
            <Products showProductDetails={showProductDetails} showProductDetailsCard={showProductDetailsCard}/>
        
            {/* <About /> */}
          </>
        )}

        {view === "products" && (
          <Products showProductDetailsCard={showProductDetailsCard}  showProductDetails={showProductDetails} />
        )}
        
      
        {view === "about" && <About />}
        {view === "CCTV" && <CCTV />}
        {view === "contact" && <Contact />}
        {view === "details" && <ProductDetails product={selectedProduct} />}
        {view === "carddetails" && <ProductDetailsCard showCCTV={showCCTV}  product={selectedProduct} />}
       
      </div>
    </>
  );
}

export default App;
